<template>
  <div>
    <template v-if="data.list.error">
      <Empty desc="" :show-btn="data.list.showErrorBtn" @empty-event="fetchRankList" />
    </template>
    <van-skeleton v-else :loading="data.list.loading" animated :style="{ flexDirection: 'column' }">
      <template #template>
        <div v-for="item in 5" :key="item" flex="~" w="full" m="y-2">
          <van-skeleton-image round />
          <div flex="1" m="l-4">
            <van-skeleton-paragraph row-width="60%" />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
          </div>
        </div>
      </template>
      <div p="b-2">
        <div v-if="route.query.query" class="search-result">
          <p m="y-2.5 l-2">
            {{ t('key.attractions.list.search.result', [confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length || '0', route.query.query as string]) }}
          </p>

          <Empty v-if="confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length === 0" desc="" btn-txt="" />

          <MobileTopAttractionsItem
            v-for="(item, index) of confirmAttrList[E_RANK_TYPE.ATTRACTIONS]"
            :key="item.attrId"
            :top-choice="index === 0"
            :item-index="index"
            :item-data="item"
            :hide-rank-num="true"
            :rank-type="E_RANK_TYPE.ATTRACTIONS"
          />
          <h2 mt="6" ml="2" mb="2.5" color="#000">
            {{ t('key.list.other.recommendations') }}
          </h2>
        </div>
        <MobileTopAttractionsItem
          v-for="(item, index) of data.list.items"
          :key="index"
          :item-index="index"
          :item-data="item"
          :rank-type="E_RANK_TYPE.ATTRACTIONS"
        />
      </div>
    </van-skeleton>
  </div>
</template>

<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

const route = useRoute()

const { t } = useI18n()
const { data, fetchRankList, fetchSearchAttraction } = useAttractionList()
const { confirmAttrList } = useSearchAttr()

const filterConditionsStore = useFilterConditionsStore()
const userInfoStore = useUserStore()

watch(
  [
    () => filterConditionsStore.filterConditions.date,
    () => userInfoStore.userInfo.currency,
    () => filterConditionsStore.filterConditions.filterIdList,
    () => filterConditionsStore.filterConditions.range,
  ],
  () => {
    fetchRankList()
    fetchSearchAttraction()
  },
  {
    immediate: true,
  },
)
</script>
