<script setup lang="ts">
import iconRank_1 from '@/assets/images/rank/top_1.png'
import iconRank_2 from '@/assets/images/rank/top_2.png'
import iconRank_3 from '@/assets/images/rank/top_3.png'
import iconRank_4 from '@/assets/images/list/icon_rank_top4.png'
import { useCommonStore } from '@/stores/common'
import { toThousands } from '@/utils'
import { getScoreText } from '@/utils/common'
import type { RankListAttrDto } from '~/apis/back/types'

const props = defineProps<{
  itemIndex: number
  itemData: RankListAttrDto
  rankType: string
  hideRankNum?: boolean
  topChoice?: boolean
}>()

const commonDataStore = useCommonStore()
const { navigatorToAttractionDetail } = useAttractionList()

const priceSymbol = commonDataStore.getCurrencySymbol

const rangImg = computed(() => {
  switch (props.itemIndex) {
    case 0:
      return iconRank_1
    case 1:
      return iconRank_2
    case 2:
      return iconRank_3
    default:
      return iconRank_4
  }
})

function clickItem() {
  navigatorToAttractionDetail(props, !props.hideRankNum)
}
</script>

<template>
  <div flex="~ items-stretch" p="4" class="azgo-haptics-feedback" @click.stop="clickItem">
    <div class="relative">
      <van-image h="full" :src="itemData.attrImg" fit="cover" style="vertical-align: middle;" w="25" min-h="31" flex="shrink-0" radius="0.75rem" />
      <div class="absolute" top="-1" left="-1">
        <img :src="rangImg" w="9" class="absolute" />
        <p v-if="itemIndex > 2" mt="2" ml="2.5" class="relative" text="sm #683815 center" font="700">
          {{ itemIndex < 9 ? `0${itemIndex + 1}` : itemIndex + 1 }}
        </p>
      </div>
    </div>

    <div ml="3" h="full" flex="1" overflow-x="hidden">
      <div text="sm #121212" font="500" line-clamp="2">
        {{ itemData.attrName }}
      </div>
      <div mt="1.5" flex="~ items-center">
        <div w="6" leading="4" style="background-color: var(--tc-color-primary);" rounded="1" text="xs center white" font="500">
          {{ Number(itemData.rating).toFixed(1) }}
        </div>
        <span ml="1" text="#121212 xs" font="300">{{ getScoreText(Number(itemData.rating)) }}</span>
      </div>
      <div flex="~ items-center" mt="2">
        <NuxtIcon mr="0.5" name="my-icon:location" text="xs #808080" />
        <span text="xs #808080" font="300">{{ itemData.address }}</span>
      </div>
      <div class="relative" w="full">
        <div space-x="2" mt="2" flex="~ items-center nowrap" overflow-x="auto">
          <div v-for="item in itemData.minPriceRecords" :key="item.pkId" class="azgo-haptics-feedback" px="2" py="1.5" bg="#F6F6F6" rounded="2" w="fit">
            <div flex="~ items-center">
              <img w="3" h="3" rounded="1" mr="0.5" :src="item.logoUrl" />
              <div text="2.5 #121212" font="300">
                Trip.com
              </div>
            </div>
            <div mt="1" text="sm #121212" flex="~ items-center">
              <span mr="0.5">{{ priceSymbol }}{{ toThousands(item.minPrice) }}</span>
              <img src="~/assets/images/search-result/jump.png" w="4" h="4" />
            </div>
          </div>
          <div class="absolute" right="0" top="0" w="8" h="full" style="background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)"></div>
        </div>
      </div>
    </div>
  </div>
  <div mx="4" class="van-hairline--top"></div>
</template>

<style lang="scss" scoped>
.list-item {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;

  .item-left {
    position: relative;

    .item-img {
      width: 102px;
      height: 96px;
      object-fit: cover;
      border-radius: 10px;
    }

    .item-rank {
      position: absolute;
      left: 10px;
      top: 0;
      width: 28px;
      height: 32px;

      > p {
        margin-top: 4px;
        // margin: 0 auto;
        width: 28px;
        // height: 32px;
        color: #683815;
        text-align: center;
        font: {
          size: 15px;
          weight: 700;
        }
      }
    }

    .item-rank-top {
      width: 33px;
      height: 38px;
    }
  }

  .item-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px 0;
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;

      .top-rank {
        width: 34px;
        height: 34px;
      }

      .top-title {
        // color: #404040;
        font: {
          size: 13px;
          weight: 600;
        }
        // 限制一行
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex: 1;
      overflow: hidden;

      padding: 20px 0 0 0;
      // background-color: blue;

      .bottom-left {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 2px;
        overflow: hidden;

        // background-color: red;

        > p {
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
        }

        &-sites {
          color: #777474;
          font: {
            size: 12px;
            weight: 600;
          }
        }

        &-price {
          margin-top: 5px;
          color: #000000;
          font: {
            size: 18px;
            weight: 600;
          }
        }

        &-detail {
          white-space: nowrap;
          font: {
            size: 10px;
            font-weight: 400;
          }
        }
      }

      .bottom-right {
        flex-shrink: 0;
        margin-bottom: 2px;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

$card-radius: 12px;

.card-content {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: var(--el-color-primary-light-9);

  .attr-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 18.75px;
    margin-bottom: 5px;
  }
}

.card-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: #777474;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
  }

  .cashback-desc {
    color: var(--tc-color-primary);
  }
}

.first-card {
  border: 1px solid var(--tc-color-primary);
  border-radius: $card-radius;
  background-color: var(--tc-color-primary);
  overflow: hidden;
  margin-bottom: 10px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100%;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
  }

  h4 {
    line-height: 32px;
    height: 32px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding-left: 12px;
  }
}
</style>
